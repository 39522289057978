import { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SignUpTenantStep from './SignUpTenantStep';
import SignUpAdminStep from './SignUpAdminStep';
import SignUpTermsAndConditionsStep from './SignUpTermsAndConditionsStep';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearState, createTenantThunk, selectSignup } from './signupSlice';
import SignUpSuccess from './SignUpSuccess';
import logo from '../../assets/finegym-logo-white.svg';

export default function SignUp() {
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(0);
  const dispatch = useAppDispatch();
  const { isSuccess } = useAppSelector(selectSignup);

  const singUpSteps = useMemo(
    () => [
      {
        title: t('Institution Setup'),
        component: <SignUpTenantStep onNextStepClick={() => setStepIndex(1)} />,
      },
      {
        title: t('Admin Account'),
        component: (
          <SignUpAdminStep
            onNextStepClick={() => setStepIndex(2)}
            onPrevStepClick={() => setStepIndex(0)}
          />
        ),
      },
      {
        title: t('Terms & Conditions'),
        component: (
          <SignUpTermsAndConditionsStep
            onPrevStepClick={() => setStepIndex(1)}
            onNextStepClick={() => dispatch(createTenantThunk())}
          />
        ),
      },
    ],
    [dispatch, t],
  );

  const currentStep = useMemo(
    () => singUpSteps[stepIndex]?.component ?? <SignUpSuccess />,
    [singUpSteps, stepIndex],
  );

  useEffect(
    () =>
      function cleanUp() {
        dispatch(clearState());
      },
    [dispatch],
  );

  return (
    <section className="py-8 bg-white dark:bg-gray-900 lg:py-0">
      <div className="lg:flex">
        <div className="hidden w-full max-w-md p-12 lg:h-screen lg:flex flex-col bg-primary-600">
          <div className="flex items-center mb-8 space-x-4">
            <NavLink
              to="/"
              className="flex items-center text-2xl font-semibold text-white"
            >
              <svg
                className="w-6 h-6 mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              {t('Go back')}
            </NavLink>
          </div>

          <div className="w-full h-full flex flex-col justify-center items-center gap-y-3">
            <img className="w-3/5 h-auto" src={logo} alt="company logo" />
            <NavLink to="/">
              <h1 className="text-5xl text-white font-bold">{t('FineGym')}</h1>
            </NavLink>
          </div>
        </div>
        <div className="flex items-center mx-auto md:w-[42rem] px-4 md:px-8 xl:px-0">
          <div className="w-full">
            <div className="flex items-center justify-center mb-8 space-x-4 lg:hidden">
              <NavLink
                to="/"
                className="flex items-center text-2xl font-semibold"
              >
                <img className="w-8 h-8 mr-2" src={logo} alt="logo" />
                <span className="text-gray-900 dark:text-white">
                  {t('Finegym')}
                </span>
              </NavLink>
            </div>

            {isSuccess ? (
              <SignUpSuccess />
            ) : (
              <>
                <ol className="flex flex-wrap items-center mb-6 text-sm font-medium text-center text-gray-500 dark:text-gray-400 lg:mb-12 sm:text-base">
                  {singUpSteps.map((step, i) => {
                    const stepNumber = i + 1;
                    if (singUpSteps.length - 1 === i) {
                      return (
                        <li
                          key={step.title}
                          className="flex items-center sm:block"
                        >
                          <div className="mr-2 sm:mb-2 sm:mx-auto">
                            {stepNumber}
                          </div>
                          {step.title}
                        </li>
                      );
                    }
                    return stepIndex <= i ? (
                      <li
                        key={step.title}
                        className="flex items-center after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"
                      >
                        <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
                          <div className="mr-2 sm:mb-2 sm:mx-auto">
                            {stepNumber}
                          </div>
                          {step.title}
                        </div>
                      </li>
                    ) : (
                      <li
                        key={step.title}
                        className="flex items-center text-primary-600 dark:text-primary-500 sm:after:content-[''] after:w-12 after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700"
                      >
                        <div className="flex items-center sm:block after:content-['/'] sm:after:hidden after:mx-2 after:font-light after:text-gray-200 dark:after:text-gray-500">
                          <svg
                            className="w-4 h-4 mr-2 sm:mb-2 sm:w-6 sm:h-6 sm:mx-auto"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                              clipRule="evenodd"
                            />
                          </svg>
                          {step.title}
                        </div>
                      </li>
                    );
                  })}
                </ol>
                {currentStep}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
