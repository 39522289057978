import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import WithSuspenseLoadingSpinner from '../components/hoc/WithSuspenseLoadingSpinner';

const ManageReservations = lazy(
  () =>
    import(
      '../features/dashboard/membership/members/edit/upcomming-reservations/ManageReservations'
    ),
);
const ManageCommunicationHistory = lazy(
  () =>
    import(
      '../features/dashboard/membership/members/edit/communication-history/ManageCommunicationHistory'
    ),
);
const ManageCreditPackages = lazy(
  () =>
    import(
      '../features/dashboard/membership/members/edit/credit-packages/ManageCreditPackages'
    ),
);
const ManageDocuments = lazy(
  () =>
    import(
      '../features/dashboard/membership/members/edit/documents/ManageDocuments'
    ),
);
const ManageSignatures = lazy(
  () =>
    import(
      '../features/dashboard/membership/members/edit/signatures/ManageSignatures'
    ),
);
const MembershipPlanListPage = lazy(
  () => import('../features/dashboard/membership/membership-plans'),
);
const MembershipPlan = lazy(
  () => import('../features/dashboard/membership/membership-plans/edit'),
);
const MembershipPlanGeneral = lazy(
  () =>
    import('../features/dashboard/membership/membership-plans/edit/general'),
);
const MembersListPage = lazy(
  () => import('../features/dashboard/membership/members'),
);
const MemberProfile = lazy(
  () => import('../features/dashboard/membership/members/edit'),
);
const MemberDetails = lazy(
  () => import('../features/dashboard/membership/members/edit/profile'),
);
const ManageAttendance = lazy(
  () =>
    import(
      '../features/dashboard/membership/members/edit/attendance/ManageAttendance'
    ),
);
const ManageMemberships = lazy(
  () =>
    import(
      '../features/dashboard/membership/members/edit/membership/ManageMemberships'
    ),
);
const ManageUserNote = lazy(
  () =>
    import(
      '../features/dashboard/membership/members/edit/notes/ManageUserNotes'
    ),
);

const ManagePayments = lazy(
  () =>
    import(
      '../features/dashboard/membership/members/edit/transactions/ManagePayments'
    ),
);
const CheckInsPage = lazy(
  () => import('../features/dashboard/membership/check-ins'),
);

export const dashboardMembershipRoutes = (
  <Route>
    <Route path="members">
      <Route
        index
        element={
          <WithSuspenseLoadingSpinner>
            <MembersListPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path=":memberId"
        element={
          <WithSuspenseLoadingSpinner>
            <MemberProfile />
          </WithSuspenseLoadingSpinner>
        }
      >
        <Route index element={<Navigate to="profile" replace />} />
        <Route
          path="profile"
          element={
            <WithSuspenseLoadingSpinner>
              <MemberDetails />
            </WithSuspenseLoadingSpinner>
          }
        />
        <Route
          path="attendance"
          element={
            <WithSuspenseLoadingSpinner>
              <ManageAttendance />
            </WithSuspenseLoadingSpinner>
          }
        />
        <Route
          path="membership"
          element={
            <WithSuspenseLoadingSpinner>
              <ManageMemberships />
            </WithSuspenseLoadingSpinner>
          }
        />
        <Route
          path="payments"
          element={
            <WithSuspenseLoadingSpinner>
              <ManagePayments />
            </WithSuspenseLoadingSpinner>
          }
        />
        <Route
          path="upcomming-reservations"
          element={
            <WithSuspenseLoadingSpinner>
              <ManageReservations />
            </WithSuspenseLoadingSpinner>
          }
        />
        <Route
          path="communication-history"
          element={
            <WithSuspenseLoadingSpinner>
              <ManageCommunicationHistory />
            </WithSuspenseLoadingSpinner>
          }
        />
        <Route
          path="documents"
          element={
            <WithSuspenseLoadingSpinner>
              <ManageDocuments />
            </WithSuspenseLoadingSpinner>
          }
        />
        <Route
          path="signatures"
          element={
            <WithSuspenseLoadingSpinner>
              <ManageSignatures />
            </WithSuspenseLoadingSpinner>
          }
        />
        <Route
          path="credit-packages"
          element={
            <WithSuspenseLoadingSpinner>
              <ManageCreditPackages />
            </WithSuspenseLoadingSpinner>
          }
        />
        <Route
          path="notes"
          element={
            <WithSuspenseLoadingSpinner>
              <ManageUserNote />
            </WithSuspenseLoadingSpinner>
          }
        />
      </Route>
    </Route>
    <Route path="membership-plans">
      <Route
        index
        element={
          <WithSuspenseLoadingSpinner>
            <MembershipPlanListPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path=":membershipPlanId"
        element={
          <WithSuspenseLoadingSpinner>
            <MembershipPlan />
          </WithSuspenseLoadingSpinner>
        }
      >
        <Route index element={<Navigate to="general" replace />} />
        <Route
          path="general"
          element={
            <WithSuspenseLoadingSpinner>
              <MembershipPlanGeneral />
            </WithSuspenseLoadingSpinner>
          }
        />
      </Route>
    </Route>
    <Route
      path="check-ins"
      element={
        <WithSuspenseLoadingSpinner>
          <CheckInsPage />
        </WithSuspenseLoadingSpinner>
      }
    />
  </Route>
);
