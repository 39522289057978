import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import WithSuspenseLoadingSpinner from '../components/hoc/WithSuspenseLoadingSpinner';

const ManageProductDiscount = lazy(
  () => import('../features/dashboard/products/discounts/manage'),
);
const Product = lazy(() => import('../features/dashboard/products/items/edit'));
const ProductGeneral = lazy(
  () => import('../features/dashboard/products/items/edit/general'),
);
// const ProductPreOrdersListPage = lazy(
//   () => import('../features/dashboard/products/preorders'),
// );
const ProductCategoriesPage = lazy(
  () => import('../features/dashboard/products/categories'),
);
const ProductItemsListPage = lazy(
  () => import('../features/dashboard/products/items'),
);
const DiscountListPage = lazy(
  () => import('../features/dashboard/products/discounts'),
);

export const dashboardProductsRoutes = (
  <Route>
    <Route path="discounts">
      <Route
        index
        element={
          <WithSuspenseLoadingSpinner>
            <DiscountListPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path=":discountId"
        element={
          <WithSuspenseLoadingSpinner>
            <ManageProductDiscount />
          </WithSuspenseLoadingSpinner>
        }
      />
    </Route>
    <Route path="products">
      <Route
        index
        element={
          <WithSuspenseLoadingSpinner>
            <ProductItemsListPage />
          </WithSuspenseLoadingSpinner>
        }
      />
      <Route
        path=":productId"
        element={
          <WithSuspenseLoadingSpinner>
            <Product />
          </WithSuspenseLoadingSpinner>
        }
      >
        <Route index element={<Navigate to="general" replace />} />
        <Route
          path="general"
          element={
            <WithSuspenseLoadingSpinner>
              <ProductGeneral />
            </WithSuspenseLoadingSpinner>
          }
        />
      </Route>
    </Route>
    <Route
      path="product-categories"
      element={
        <WithSuspenseLoadingSpinner>
          <ProductCategoriesPage />
        </WithSuspenseLoadingSpinner>
      }
    />
    {/* <Route
      path="product-pre-orders"
      element={
        <WithSuspenseLoadingSpinner>
          <ProductPreOrdersListPage />
        </WithSuspenseLoadingSpinner>
      }
    /> */}
  </Route>
);
