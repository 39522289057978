import { DarkThemeToggle, Select } from 'flowbite-react';
import { PropsWithChildren, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectAuth, setLanguage } from '../../features/auth/authSlice';
import { AppLanguages } from '../../i18n';

export default function WithHelperButtons({ children }: PropsWithChildren) {
  const dispatch = useAppDispatch();
  const { language } = useAppSelector(selectAuth);

  const setLocalLanguage = useCallback(
    (val: string) => {
      dispatch(setLanguage(val));
    },
    [dispatch],
  );

  return (
    <>
      {children}
      <div className="fixed z-50 text-gray-950 bottom-8 left-8 flex flex-row items-center gap-2 flex-wrap">
        <DarkThemeToggle className="dark:bg-gray-700 bg-white" />
        <Select
          id="local-settings-language"
          defaultValue={language}
          onChange={(e) => setLocalLanguage(e.target.value)}
          color="primary"
        >
          {Object.entries(AppLanguages).map(([code, label]) => (
            <option key={code} value={code}>
              {label}
            </option>
          ))}
        </Select>
      </div>
    </>
  );
}
