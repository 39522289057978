import { useCallback, useRef } from 'react';
import Keyboard, { KeyboardReactInterface } from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

interface IProps {
  onChange: (input: string) => void;
  onEnter?: () => void;
  maxLength?: number;
}

export default function NumericKeyboardWrapper({
  onChange,
  onEnter,
  maxLength,
}: IProps) {
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);

  const onKeyPress = useCallback(
    (button: string) => {
      if (button === '{enter}') {
        onEnter?.();
        keyboardRef.current?.clearInput();
      }
    },
    [onEnter],
  );

  return (
    <Keyboard
      keyboardRef={(r) => {
        keyboardRef.current = r;
      }}
      onChange={onChange}
      onKeyPress={onKeyPress}
      theme="hg-theme-default hg-layout-numeric numeric-theme !rounded-t-none !rounded-b-lg"
      layout={{
        default: ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 {enter}'],
      }}
      maxLength={maxLength}
    />
  );
}
