import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { AxiosError } from 'axios';
import type { BaseSliceState, RootState } from '../../store/types';
import { generateBoilerplateAsyncThunkMatchers } from '../../store/helpers';
import { apiAxiosInstance } from '../../utils/axiosInstance';

export interface SignupState extends BaseSliceState {
  isSuccess?: boolean;
  registrantData: {
    tenantName?: string;
    tenantDomain?: string;
    adminPassword?: string;
    adminEmail?: string;
    adminFullName?: string;
    adminCountry?: string;
    adminTimezone?: string;
    termsAndConditions?: boolean;
  };
}

const initialState: SignupState = {
  status: 'idle',
  registrantData: {},
};

const sliceId = 'signup';

export const createTenantThunk = createAsyncThunk(
  `${sliceId}/createTenant`,

  async (_undefined, { getState, rejectWithValue, fulfillWithValue }) => {
    const {
      adminEmail,
      adminPassword,
      adminFullName,
      adminCountry,
      adminTimezone,
      tenantDomain,
      tenantName,
    } = (getState() as RootState).signup.registrantData;
    try {
      const createResult = await apiAxiosInstance
        .post('/api/clients/', {
          name: tenantName,
          domain_url: tenantDomain,
          email: adminEmail,
          password: adminPassword,
          admin_name: adminFullName,
          country: adminCountry,
          timezone: adminTimezone,
        })
        .then((res) => res.data);
      return fulfillWithValue(createResult);
    } catch (error) {
      const axiosError = error as AxiosError;

      return rejectWithValue(axiosError.response?.data);
    }
  },
);

export const signupSlice = createSlice({
  name: sliceId,
  initialState,
  reducers: {
    setTenant(
      state,
      action: PayloadAction<{ tenantName: string; tenantDomain: string }>,
    ) {
      state.registrantData = { ...state.registrantData, ...action.payload };
    },
    setAdmin(
      state,
      action: PayloadAction<{
        adminPassword: string;
        adminEmail: string;
        adminFullName?: string;
        adminCountry: string;
        adminTimezone: string;
      }>,
    ) {
      state.registrantData = { ...state.registrantData, ...action.payload };
    },
    setTermsAndNewsletter(
      state,
      action: PayloadAction<{
        termsAndConditions: boolean;
      }>,
    ) {
      state.registrantData = { ...state.registrantData, ...action.payload };
    },
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => initialState)
      .addCase(createTenantThunk.fulfilled, (state) => {
        state.isSuccess = true;
      });
    generateBoilerplateAsyncThunkMatchers(builder, sliceId);
  },
});

export const selectSignup = (state: RootState) => state.signup;
export const { clearState, setAdmin, setTenant, setTermsAndNewsletter } =
  signupSlice.actions;
export default signupSlice.reducer;
