import { lazy } from 'react';
import { Route } from 'react-router-dom';
import WithSuspenseLoadingSpinner from '../components/hoc/WithSuspenseLoadingSpinner';

const Return = lazy(() => import('../features/return'));
const ReturnProductOrder = lazy(
  () => import('../features/return-product-order'),
);
const StripeConnectedAccountReauth = lazy(
  () => import('../features/stripe-connected-account-reauth'),
);
const StripeConnectedAccountSuccess = lazy(
  () => import('../features/stripe-connected-account-success'),
);
const ArticlePreview = lazy(() => import('../features/article-preview'));
const PolicyPreview = lazy(() => import('../features/policy-preview'));
const PolicyPreviewDetail = lazy(
  () => import('../features/policy-preview/detail'),
);

export const generalRoutes = (
  <Route>
    <Route
      path="return"
      element={
        <WithSuspenseLoadingSpinner>
          <Return />
        </WithSuspenseLoadingSpinner>
      }
    />
    <Route
      path="return-product-order"
      element={
        <WithSuspenseLoadingSpinner>
          <ReturnProductOrder />
        </WithSuspenseLoadingSpinner>
      }
    />
    <Route
      path="article-preview/:productId"
      element={
        <WithSuspenseLoadingSpinner>
          <ArticlePreview />
        </WithSuspenseLoadingSpinner>
      }
    />
    {/* TODO: Should stripe routes be open to all? */}
    <Route
      path="stripe-connected-account-reauth"
      element={
        <WithSuspenseLoadingSpinner>
          <StripeConnectedAccountReauth />
        </WithSuspenseLoadingSpinner>
      }
    />
    <Route
      path="stripe-connected-account-success"
      element={
        <WithSuspenseLoadingSpinner>
          <StripeConnectedAccountSuccess />
        </WithSuspenseLoadingSpinner>
      }
    />
    <Route
      path=":tenant/policy-preview"
      element={
        <WithSuspenseLoadingSpinner>
          <PolicyPreview />
        </WithSuspenseLoadingSpinner>
      }
    />
    <Route
      path=":tenant/policy-preview/:id"
      element={
        <WithSuspenseLoadingSpinner>
          <PolicyPreviewDetail />
        </WithSuspenseLoadingSpinner>
      }
    />
  </Route>
);
