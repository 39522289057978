import { Button, Card, Modal } from 'flowbite-react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import {
  useAttendancesPerformCheckInCreateMutation,
  useAttendancesPerformCheckOutCreateMutation,
  UserMembershipRead,
  useLazyMembersRetrieveQuery,
  useLazyUserMembershipsListQuery,
  useLazyAttendancesListQuery,
  useUsersRetrieveByPinCodeCreateMutation,
  useMembershipPlansRetrieveQuery,
} from '../store/finegym-rtk-query-api';
import PageLoadingSpinner from './PageLoadingSpinner';

function UserMembershipSelectableItem({
  userMembership,
  selected,
  onClick,
}: {
  userMembership: UserMembershipRead;
  selected?: boolean;
  onClick?: () => void;
}) {
  const { data: membershipPlan } = useMembershipPlansRetrieveQuery({
    id: userMembership.membership_plan,
  });

  return (
    <Card
      onClick={onClick}
      className={clsx([
        'cursor-pointer  bg-white hover:bg-gray-100 dark:text-white dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700',
        selected &&
          'bg-primary-600 text-white dark:bg-primary-600 hover:bg-primary-700 dark:hover:bg-primary-700',
      ])}
    >
      {membershipPlan?.name}
      <span>{membershipPlan?.description || '-'}</span>
    </Card>
  );
}

export type CheckInHandlerProps = {
  onCheckInOut?: (action?: 'checked-in' | 'checked-out') => void;
  children: (props: {
    isProcessing: boolean;
    checkInOrOut: (memberId: number) => Promise<void>;
    checkInOrOutWithPinCode: (pinCode: string) => Promise<void>;
  }) => React.ReactNode;
};
export default function CheckInHandler({
  children,
  onCheckInOut,
}: CheckInHandlerProps) {
  const { t } = useTranslation();

  const [isProcessing, setIsProcessing] = useState(false);

  const [performCheckIn] = useAttendancesPerformCheckInCreateMutation();
  const [performCheckOut] = useAttendancesPerformCheckOutCreateMutation();
  const [retrieveUserByPin] = useUsersRetrieveByPinCodeCreateMutation();
  const [retrieveUser, { isLoading: isFetchingUserProfile }] =
    useLazyMembersRetrieveQuery();
  const [retrieveUserMemberships, { isLoading: isFetchingMemberships }] =
    useLazyUserMembershipsListQuery();
  const [retrieveUserAttendanceList, { isLoading: isFetchingAttendance }] =
    useLazyAttendancesListQuery();

  const [openModal, setOpenModal] = useState(false);
  const [userMemberships, setUserMemberships] =
    useState<UserMembershipRead[]>();
  const [selectedUserMembership, setSelectedUserMembership] =
    useState<number>();
  const [tempMemberId, setTempMemberId] = useState<number>();

  const clearState = useCallback(() => {
    setUserMemberships(undefined);
    setSelectedUserMembership(undefined);
    setTempMemberId(undefined);
    setOpenModal(false);
  }, []);

  const verifyIsMemberCheckedIn = useCallback(
    async (memberId: number) => {
      const attendance = await retrieveUserAttendanceList(
        {
          checkOutIsnull: true,
          user: memberId,
        },
        true,
      ).unwrap();
      return !!attendance?.count;
    },
    [retrieveUserAttendanceList],
  );

  // const isDoingCheckInOut = useMemo(
  //   () => isDoingCheckIn || isDoingCheckOut,
  //   [isDoingCheckIn, isDoingCheckOut],
  // );

  const isLoadingData = useMemo(
    () =>
      isFetchingMemberships || isFetchingUserProfile || isFetchingAttendance,
    [isFetchingAttendance, isFetchingMemberships, isFetchingUserProfile],
  );

  const triggerCheckInOut = useCallback(
    async (memberId: number) => {
      const isCheckedIn = await verifyIsMemberCheckedIn(memberId);
      if (isCheckedIn) {
        // CHECK OUT
        await performCheckOut({
          performCheckOut: { user: memberId },
        }).unwrap();
        toast.success(t('Member checked out successfully'), {
          toastId: 'member-check-out-success',
        });
        clearState();
        if (onCheckInOut) {
          onCheckInOut('checked-out');
        }
      } else {
        // CHECK IN
        const memberships = await retrieveUserMemberships(
          {
            user: memberId,
            isActive: true,
            membershipPlanAllowCheckIns: true,
            isArchived: false,
          },
          true,
        ).unwrap();

        if (memberships?.results.length) {
          if (memberships.results.length > 1 && !openModal) {
            setOpenModal(true);
            setUserMemberships(memberships.results);
            setTempMemberId(memberId);
          } else {
            const userMembership =
              selectedUserMembership || memberships.results[0].id;
            await performCheckIn({
              performCheckIn: { user_membership: userMembership },
            }).unwrap();
            toast.success(t('Member checked in successfully'), {
              toastId: 'member-check-in-success',
            });
            clearState();
            if (onCheckInOut) {
              onCheckInOut('checked-in');
            }
          }
        }
      }
    },
    [
      clearState,
      openModal,
      performCheckIn,
      performCheckOut,
      retrieveUserMemberships,
      selectedUserMembership,
      setOpenModal,
      setUserMemberships,
      setTempMemberId,
      t,
      verifyIsMemberCheckedIn,
      onCheckInOut,
    ],
  );

  const checkInOrOut = useCallback(
    async (memberId: number) => {
      setIsProcessing(true);
      try {
        const member = await retrieveUser({ id: memberId }, true).unwrap();
        await triggerCheckInOut(member.id);
      } finally {
        setIsProcessing(false);
      }
    },
    [retrieveUser, triggerCheckInOut],
  );

  const checkInOrOutWithPinCode = useCallback(
    async (pinCode: string) => {
      setIsProcessing(true);
      try {
        const member = await retrieveUserByPin({
          pinCode: { pin_code: pinCode },
        }).unwrap();
        await triggerCheckInOut(member.id);
      } finally {
        setIsProcessing(false);
      }
    },
    [retrieveUserByPin, triggerCheckInOut],
  );

  return (
    <>
      {children({
        isProcessing,
        checkInOrOut,
        checkInOrOutWithPinCode,
      })}
      <Modal show={openModal} onClose={clearState} dismissible>
        <Modal.Header>{t('Select membership plan')}</Modal.Header>
        <Modal.Body>
          {isLoadingData ? (
            <PageLoadingSpinner />
          ) : (
            <div className="w-full flex flex-col p-2 gap-4">
              {userMemberships?.map((userMembership) => (
                <UserMembershipSelectableItem
                  key={userMembership.id}
                  userMembership={userMembership}
                  selected={selectedUserMembership === userMembership.id}
                  onClick={() => setSelectedUserMembership(userMembership.id)}
                />
              ))}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={clearState} disabled={isProcessing}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={!selectedUserMembership || isProcessing}
            isProcessing={isProcessing}
            onClick={() => tempMemberId && checkInOrOut(tempMemberId)}
          >
            {t('Check in')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
