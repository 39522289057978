import { useTranslation } from 'react-i18next';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { Spinner } from 'flowbite-react'; // Assuming Spinner is from flowbite-react

type TablePaginationProps = {
  page: number;
  count: number;
  perPage?: number;
  onNextPageClick?: () => void;
  onPreviousPageClick?: () => void;
  isLoading?: boolean;
};

export default function TablePagination({
  count,
  page,
  perPage = 20,
  onNextPageClick,
  onPreviousPageClick,
  isLoading = false,
}: TablePaginationProps) {
  const { t } = useTranslation();

  return (
    <div className="sticky bottom-0 right-0 w-full items-center border-t border-gray-200 bg-white p-4 dark:border-gray-700 dark:bg-gray-800 sm:flex sm:justify-between">
      <div className="mb-4 flex items-center sm:mb-0">
        <button
          disabled={!onPreviousPageClick || isLoading}
          type="button"
          className="inline-flex cursor-pointer justify-center rounded p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          onClick={onPreviousPageClick}
        >
          <span className="sr-only">{t('Previous page')}</span>
          <HiChevronLeft className="text-2xl" />
        </button>

        <button
          disabled={!onNextPageClick || isLoading}
          type="button"
          className="mr-2 inline-flex cursor-pointer justify-center rounded p-1 text-gray-500 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          onClick={onNextPageClick}
        >
          <span className="sr-only">{t('Next page')}</span>
          <HiChevronRight className="text-2xl" />
        </button>
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          {t('Showing')}
          &nbsp;
          <span className="font-semibold text-gray-900 dark:text-white">
            {page * perPage - (perPage - 1)} -{' '}
            {count < page * perPage ? count : page * perPage}
          </span>{' '}
          {t('of')}{' '}
          <span className="font-semibold text-gray-900 dark:text-white">
            {count ?? '~'}
          </span>
        </span>
        {isLoading && (
          <div className="ml-4">
            <Spinner color="primary" />
          </div>
        )}
      </div>
    </div>
  );
}
