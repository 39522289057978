import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner';
import { Modal, Button } from 'flowbite-react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HiPencilAlt, HiUser, HiX } from 'react-icons/hi';
import { useAppSelector } from '../store/hooks';
import { selectAuth } from '../features/auth/authSlice';
import { decodeBase64 } from '../utils/stringUtils';
import CheckInHandler from './CheckInHandler';
import { useMembersRetrieveQuery } from '../store/finegym-rtk-query-api';
import useIsMemberCheckedIn from '../hooks/useIsMemberCheckedIn';

type MemberQRCodeScannerModalUtils = {
  openModal: () => void;
  closeModal: () => void;
};

export type MemberQRCodeScannerModalProps = {
  renderButton: (params: MemberQRCodeScannerModalUtils) => JSX.Element;
};

export default function MemberQRCodeScannerModal({
  renderButton,
}: MemberQRCodeScannerModalProps) {
  const { t } = useTranslation();
  const [user, setUser] = useState<number>();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const { tenantDomain } = useAppSelector(selectAuth);

  const { data: userProfile, isLoading: isFetchingUserProfile } =
    useMembersRetrieveQuery({ id: user! }, { skip: !user });

  const goToProfile = useCallback(() => {
    if (user) {
      closeModal();
      navigate(`/dashboard/members/${user}`);
    }
  }, [navigate, user]);

  const onDecodeCallback = useCallback(
    (detectedCodes: IDetectedBarcode[]) => {
      const decodedResult = decodeBase64(detectedCodes[0].rawValue);
      if (!!tenantDomain && decodedResult?.includes?.(tenantDomain)) {
        const userId = decodedResult.split('-')?.[2];
        if (!Number.isNaN(+userId)) {
          setUser(+userId);
        }
      }
    },
    [tenantDomain],
  );

  useEffect(
    () =>
      function cleanUp() {
        setUser(undefined);
      },
    [],
  );

  const { isCheckedIn, isLoading: isLoadingCheckedInState } =
    useIsMemberCheckedIn(userProfile?.id, { skip: !userProfile?.id });

  return (
    <>
      {renderButton({ openModal, closeModal })}
      {modalOpen && (
        <Modal show onClose={closeModal} dismissible>
          <Modal.Header>
            {t("Scan member's QR code to see details")}
          </Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              <Scanner onScan={onDecodeCallback} />
            </div>
            {!!userProfile?.id && (
              <div className="w-full flex flex-col items-start">
                <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400 my-4">
                  {t('User successfully scanned.')}
                </p>
                <div className="flex flex-row gap-2">
                  <CheckInHandler>
                    {({ isProcessing, checkInOrOut }) => {
                      const isLoading =
                        isFetchingUserProfile ||
                        isProcessing ||
                        isLoadingCheckedInState;
                      return (
                        <Button
                          outline
                          pill
                          isProcessing={isLoading}
                          disabled={isLoading}
                          onClick={() => checkInOrOut(userProfile.id)}
                        >
                          <HiPencilAlt className="h-6 w-6" />
                          {isLoading
                            ? null
                            : isCheckedIn
                              ? t('Check Out')
                              : t('Check In')}
                        </Button>
                      );
                    }}
                  </CheckInHandler>
                  <Button
                    color="info"
                    outline
                    pill
                    isProcessing={isFetchingUserProfile}
                    disabled={isFetchingUserProfile}
                    onClick={goToProfile}
                  >
                    <HiUser className="h-6 w-6" />
                    {t('Profile')}
                  </Button>
                  <Button
                    color="gray"
                    outline
                    pill
                    isProcessing={isFetchingUserProfile}
                    disabled={isFetchingUserProfile}
                    onClick={() => setUser(undefined)}
                  >
                    <HiX className="h-6 w-6" />
                    {t('Cancel')}
                  </Button>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              color="gray"
              onClick={closeModal}
              isProcessing={isFetchingUserProfile}
              disabled={isFetchingUserProfile}
            >
              {t('Close')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
