import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { BaseSliceState, RootState } from '../../store/types';
// import { PURGE } from 'redux-persist';
// import { generateBoilerplateAsyncThunkMatchers } from '../../store/helpers';

export interface DashboardState extends BaseSliceState {
  isCheckedInMembersDrawerOpen: boolean;
}

const initialState: DashboardState = {
  status: 'idle',
  isCheckedInMembersDrawerOpen: false,
};

const sliceId = 'dashboard';

export const dashboardSlice = createSlice({
  name: sliceId,
  initialState,
  reducers: {
    setCheckedInMembersDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isCheckedInMembersDrawerOpen = action.payload;
    },
    clearState() {
      return initialState;
    },
  },
  //   extraReducers: (builder) => {
  //     builder
  //       .addCase(PURGE, () => initialState);
  //     generateBoilerplateAsyncThunkMatchers(builder, sliceId);
  //   },
});

export const selectDashboard = (state: RootState) => state.dashboard;
export const { clearState, setCheckedInMembersDrawerOpen } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
