import {
  Middleware,
  MiddlewareAPI,
  isRejectedWithValue,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Fragment } from 'react';
import { signOutUser } from '../features/auth/authSlice';
import type { AppDispatch } from './types';

interface ErrorDetail {
  detail?: string;
  code?: string;
}

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    // eslint-disable-next-line no-console
    console.info('We got a rejected action!', action);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = (action.payload as any)?.data ?? action.payload;

    // * Skip validation errors
    // if (data.type === 'validation_error') {
    //   return next(action);
    // }

    // * CASE 1
    const messages: string[] =
      data?.errors?.map((e: ErrorDetail) => e.detail || '') || [];

    if (!messages.length) {
      messages.push('Something went wrong.');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((action.payload as any)?.status === 401) {
      const unauthorizedMsg =
        data?.errors?.find?.(
          (e: ErrorDetail) => e?.code === 'no_active_account',
        )?.detail || 'Your session has expired, please sign in again.';
      toast.error(unauthorizedMsg, {
        toastId: 'unauthorized',
      });
    } else {
      toast.error(
        <div>
          {messages.map((message, i, arr) => (
            <Fragment key={JSON.stringify(message)}>
              <p>{message}</p>
              {i < arr.length - 1 && <br />}
            </Fragment>
          ))}
        </div>,
        { toastId: JSON.stringify(messages) },
      );
    }
  }

  return next(action);
};

export const rtkQueryHandleUnauthorizedError: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((action.payload as any)?.status === 401) {
        (api.dispatch as AppDispatch)(signOutUser());
      }
    }
    return next(action);
  };

const customMiddleware = [rtkQueryHandleUnauthorizedError, rtkQueryErrorLogger];

export default customMiddleware;
