import classNames from 'classnames';
import { Sidebar } from 'flowbite-react';
import {
  HiChartPie,
  HiCog,
  HiShoppingBag,
  HiOutlineShoppingBag,
  HiTag,
  HiUsers,
  HiOutlineDuplicate,
  HiCheckCircle,
  HiCash,
  HiReceiptTax,
  HiInformationCircle,
  HiOutlineTicket,
  HiCalendar,
  HiClipboard,
  HiHome,
  HiCurrencyEuro,
  HiUserGroup,
  HiIdentification,
  HiRefresh,
} from 'react-icons/hi';
import { MdManageAccounts } from 'react-icons/md';

import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useSidebarContext } from '../context/SidebarContext';
import isSmallScreen from '../helpers/is-small-screen';
import SidebarBusinessInfoCard from './SidebarBusinessInfoCard';

type ExpandableSidebarItem =
  | 'Memberships'
  | 'Appointments'
  | 'Schedule'
  | 'Products'
  | 'Payments'
  | 'Documents & Legal'
  | 'Reports';

function SidebarContainer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isOpenOnSmallScreens: isSidebarOpenOnSmallScreens } =
    useSidebarContext();

  const [expandedItem, setExpandedItem] = useState<ExpandableSidebarItem>();

  const expandItem = useCallback((val: ExpandableSidebarItem) => {
    setExpandedItem((curr) => (curr === val ? undefined : val));
  }, []);

  return (
    <div
      className={classNames('lg:!block', {
        hidden: !isSidebarOpenOnSmallScreens,
      })}
    >
      <Sidebar
        aria-label="Sidebar with multi-level dropdown example"
        collapsed={isSidebarOpenOnSmallScreens && !isSmallScreen()}
        className="[&>*:first-child]:rounded-none"
      >
        <div className="flex h-full flex-col justify-between">
          <div className="overflow-y-auto">
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <Sidebar.Item
                  onClick={() => navigate('/dashboard')}
                  icon={HiHome}
                  className={clsx([
                    pathname === '/dashboard'
                      ? 'bg-gray-100 dark:bg-gray-700'
                      : '',
                    'cursor-pointer',
                  ])}
                >
                  {t('Dashboard')}
                </Sidebar.Item>
              </Sidebar.ItemGroup>

              <Sidebar.ItemGroup>
                <Sidebar.Item
                  onClick={() => navigate('/dashboard/members')}
                  icon={HiUsers}
                  className={clsx([
                    pathname.startsWith('/dashboard/members/') ||
                    pathname === '/dashboard/members'
                      ? 'bg-gray-100 dark:bg-gray-700'
                      : '',
                    'cursor-pointer',
                  ])}
                >
                  {t('Members')}
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate('/dashboard/membership-plans')}
                  icon={HiIdentification}
                  className={clsx(
                    pathname.startsWith('/dashboard/membership-plans')
                      ? 'bg-gray-100 dark:bg-gray-700'
                      : '',
                    'cursor-pointer',
                  )}
                >
                  {t('Membership Plans')}
                </Sidebar.Item>

                <Sidebar.Item
                  onClick={() => navigate('/dashboard/staff')}
                  icon={MdManageAccounts}
                  className={clsx([
                    pathname.startsWith('/dashboard/staff')
                      ? 'bg-gray-100 dark:bg-gray-700'
                      : '',
                    'cursor-pointer',
                  ])}
                >
                  {t('Staff')}
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate('/dashboard/migrations')}
                  icon={HiRefresh}
                  className={clsx([
                    pathname.startsWith('/dashboard/migrations')
                      ? 'bg-gray-100 dark:bg-gray-700'
                      : '',
                    'cursor-pointer',
                  ])}
                >
                  {t('Migrations')}
                </Sidebar.Item>
              </Sidebar.ItemGroup>

              <Sidebar.ItemGroup>
                <Sidebar.Item
                  onClick={() => navigate('/dashboard/calendar')}
                  icon={HiCalendar}
                  className={clsx(
                    pathname === '/dashboard/calendar'
                      ? 'bg-gray-100 dark:bg-gray-700'
                      : '',
                    'cursor-pointer',
                  )}
                >
                  {t('Calendar')}
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate('/dashboard/appointments')}
                  icon={HiClipboard}
                  className={clsx(
                    pathname.startsWith('/dashboard/appointments')
                      ? 'bg-gray-100 dark:bg-gray-700'
                      : '',
                    'cursor-pointer',
                  )}
                >
                  {t('Appointments')}
                </Sidebar.Item>
                {/* <Sidebar.Collapse
                  icon={HiClock}
                  label={t('Schedule')}
                  open={expandedItem === 'Schedule'}
                  onClick={() => expandItem('Schedule')}
                > */}
                <Sidebar.Item
                  onClick={() => navigate('/dashboard/classes')}
                  icon={HiUserGroup}
                  className={clsx(
                    pathname.startsWith('/dashboard/classes')
                      ? 'bg-gray-100 dark:bg-gray-700'
                      : '',
                    'cursor-pointer',
                  )}
                >
                  {t('Classes')}
                </Sidebar.Item>
                {import.meta.env.VITE_V2_EVENT_FEATURE_ENABLED === 'true' && (
                  <Sidebar.Item
                    onClick={() => navigate('/dashboard/events')}
                    icon={HiOutlineTicket}
                    className={clsx(
                      pathname.startsWith('/dashboard/events')
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : '',
                      'cursor-pointer',
                    )}
                  >
                    {t('Events')}
                  </Sidebar.Item>
                )}
                {/* </Sidebar.Collapse> */}
                <Sidebar.Item
                  onClick={() => navigate('/dashboard/check-ins')}
                  icon={HiCheckCircle}
                  className={clsx(
                    pathname === '/dashboard/check-ins'
                      ? 'bg-gray-100 dark:bg-gray-700'
                      : '',
                    'cursor-pointer',
                  )}
                >
                  {t('Check-Ins')}
                </Sidebar.Item>
              </Sidebar.ItemGroup>

              <Sidebar.ItemGroup>
                <Sidebar.Collapse
                  icon={HiShoppingBag}
                  label={t('Products')}
                  open={expandedItem === 'Products'}
                  onClick={() => expandItem('Products')}
                >
                  <Sidebar.Item
                    onClick={() => navigate('/dashboard/products')}
                    icon={HiOutlineShoppingBag}
                    className={clsx(
                      pathname.startsWith('/dashboard/products')
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : '',
                      'cursor-pointer',
                    )}
                  >
                    {t('Articles')}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate('/dashboard/product-categories')}
                    icon={HiOutlineDuplicate}
                    className={clsx(
                      pathname === '/dashboard/product-categories'
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : '',
                      'cursor-pointer',
                    )}
                  >
                    {t('Categories')}
                  </Sidebar.Item>
                  {/* <Sidebar.Item
                    onClick={() => navigate('/dashboard/product-pre-orders')}
                    icon={HiOutlineCalendar}
                    className={clsx(
                      pathname === '/dashboard/product-pre-orders'
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : '',
                      'cursor-pointer',
                    )}
                  >
                    {t('Preorders')}
                  </Sidebar.Item> */}
                </Sidebar.Collapse>
                <Sidebar.Item
                  onClick={() => navigate('/dashboard/discounts')}
                  icon={HiTag}
                  className={clsx(
                    pathname.startsWith('/dashboard/discounts')
                      ? 'bg-gray-100 dark:bg-gray-700'
                      : '',
                    'cursor-pointer',
                  )}
                >
                  {t('Discounts')}
                </Sidebar.Item>
                <Sidebar.Collapse
                  icon={HiCash}
                  label={t('Payments')}
                  open={expandedItem === 'Payments'}
                  onClick={() => expandItem('Payments')}
                >
                  <Sidebar.Item
                    onClick={() => navigate('/dashboard/invoices')}
                    icon={HiReceiptTax}
                    className={clsx([
                      pathname.startsWith('/dashboard/invoices')
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : '',
                      'cursor-pointer',
                    ])}
                  >
                    {t('Invoices')}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate('/dashboard/transactions')}
                    icon={HiCurrencyEuro}
                    className={clsx([
                      pathname === '/dashboard/transactions'
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : '',
                      'cursor-pointer',
                    ])}
                  >
                    {t('Transactions')}
                  </Sidebar.Item>
                </Sidebar.Collapse>

                <Sidebar.Collapse
                  icon={HiChartPie}
                  label={t('Reports')}
                  open={expandedItem === 'Reports'}
                  onClick={() => expandItem('Reports')}
                >
                  <Sidebar.Item
                    onClick={() =>
                      navigate('/dashboard/reports/aggregated-revenue')
                    }
                    className={clsx([
                      pathname === '/dashboard/reports/aggregated-revenue'
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : '',
                      'cursor-pointer',
                    ])}
                  >
                    {t('Revenue')}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() =>
                      navigate('/dashboard/reports/aggregated-members-count')
                    }
                    className={clsx([
                      pathname === '/dashboard/reports/aggregated-members-count'
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : '',
                      'cursor-pointer',
                    ])}
                  >
                    {t('Member Signups')}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() =>
                      navigate('/dashboard/reports/aggregated-user-visits')
                    }
                    className={clsx([
                      pathname === '/dashboard/reports/aggregated-user-visits'
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : '',
                      'cursor-pointer',
                    ])}
                  >
                    {t('User Visits')}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() =>
                      navigate('/dashboard/reports/referal-sources')
                    }
                    className={clsx([
                      pathname === '/dashboard/reports/referal-sources'
                        ? 'bg-gray-100 dark:bg-gray-700'
                        : '',
                      'cursor-pointer',
                    ])}
                  >
                    {t('Referal Sources')}
                  </Sidebar.Item>
                </Sidebar.Collapse>

                <Sidebar.Item
                  href="https://finegym-help.freshdesk.com/"
                  icon={HiInformationCircle}
                  target="_blank"
                >
                  {t('Help & Guides')}
                </Sidebar.Item>
              </Sidebar.ItemGroup>
              <Sidebar.ItemGroup>
                <div>
                  <SidebarBusinessInfoCard
                    show={!(isSidebarOpenOnSmallScreens && !isSmallScreen())}
                  />
                  <Sidebar.Items>
                    <Sidebar.ItemGroup>
                      {isSidebarOpenOnSmallScreens && !isSmallScreen() && (
                        <Sidebar.Item
                          onClick={() =>
                            navigate('/dashboard/business-settings')
                          }
                          icon={HiCog}
                          className={clsx(
                            pathname === '/dashboard/business-settings'
                              ? 'bg-gray-100 dark:bg-gray-700'
                              : '',
                            'cursor-pointer',
                          )}
                        >
                          {t('Business settings')}
                        </Sidebar.Item>
                      )}
                    </Sidebar.ItemGroup>
                  </Sidebar.Items>
                </div>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
            {/* <BottomMenu /> */}
            {/* <BottomMenu /> */}
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default SidebarContainer;
