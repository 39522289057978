import { Banner } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { HiX } from 'react-icons/hi';
import { MdAnnouncement } from 'react-icons/md';
import { Link } from 'react-router-dom';
import usePlan from '../hooks/usePlan';

export default function FreeVersionBanner() {
  const { t } = useTranslation();

  const {
    current: { isLoading, subscription },
  } = usePlan();

  return (
    !isLoading &&
    !subscription?.id && (
      <Banner>
        <div className="flex w-full justify-between border-b border-gray-200 bg-white p-4 pb-5 dark:border-gray-600 dark:bg-gray-700">
          <div className="mx-auto flex items-center">
            <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
              <MdAnnouncement className="mr-4 h-4 w-4" />
              <span className="[&_p]:inline">
                {t('You are on the Free limited plan.')}&nbsp;
                <Link
                  to="/dashboard/business-settings/my-plan"
                  className="inline font-medium text-primary-600 underline decoration-solid underline-offset-2 hover:no-underline dark:text-primary-500"
                >
                  {t('Upgrade now')}
                </Link>
                &nbsp;{t('to unlock all app features.')}
              </span>
            </p>
          </div>
          <Banner.CollapseButton
            color="gray"
            className="border-0 bg-transparent text-gray-500 dark:text-gray-400"
          >
            <HiX className="h-4 w-4" />
          </Banner.CollapseButton>
        </div>
      </Banner>
    )
  );
}
