import { Button } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { selectSignup } from './signupSlice';

export default function SignUpSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { registrantData } = useAppSelector(selectSignup);
  return (
    <>
      <svg
        className="w-12 h-12 mb-4 text-green-400"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        />
      </svg>
      <h1 className="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 leding-tight dark:text-white">
        {t('Welcome to FineGym!')}
      </h1>
      <p className="mb-4  text-gray-500 dark:text-gray-400 md:mb-6">
        {/* {t(
          'You have successfully created an account for you business. Log in with your',
        )}{' '}
        <strong>{t('Admin')}</strong> {t('account to get started.')} */}
        {t('Congratulations')},{registrantData.tenantName}!{' '}
        {t(
          "Your FineGym account has been set up successfully. You're all set to log in and start personalizing your experience.",
        )}
      </p>
      <Button
        color="primary"
        className="w-full"
        size="lg"
        type="button"
        onClick={() => navigate('/auth')}
      >
        {t('Log in to your Account')}
      </Button>
    </>
  );
}
